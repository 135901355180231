import React  from "react"

//styles
import "../styles/index.css"
import "../styles/header.css"
import "../styles/banner.css"
import "../styles/footer.css"
import "../styles/sbanner.css"
import "../styles/services.css"
import "../styles/servicesTemp.css"
import "../styles/contact.css"
import "../styles/careers.css"
import "../styles/serviceCard.css"
import "../styles/ourStory.css"
import "../styles/blog.css"

//testing. remove later
// components
import Header from "../components/Header.js";
import Banner from "../components/Banner.js"
import Footer from "../components/Footer.js";
import ServiceCard from "../components/ServiceCard.js";

// components
import Layout from "../components/Layout";

const OurStoryPage = () => {

  return (
      <Layout title="Our Story" path="/ Our Story">
      <title>Co-op Homecare | Our Story</title>
      <div className="story-content">
        <p>
          As the US senior population experiences unprecedented growth, the home care industry is poised to grow rapidly to accommodate the growing demand for seniors to age-in-place, in the privacy and comfort of their own homes. 
        </p>
        <p>
          <a href="http://www.cccd.coop/" target="_blank" rel="noopener noreferrer">The California Center for Cooperative Development (CCCD)</a>, a non-profit dedicated to the development of cooperatives as a vibrant solution to the economic and social needs of California’s communities, conducted research that unveiled an astonishing and disheartening picture of the home care industry. Low wages, the lack of advancement opportunities, and poor working conditions contributed to a ‘caregiver crisis’ where many sorely needed care professionals are leaving the industry, and few are willing to enter. 
        </p>
        <p>
          CCCD staff also spoke with many caregivers and home care consumers who only confirmed the data. Many caregivers felt like ‘cogs in a machine’ when working for conventional agencies. They were underpaid, did not have control of their schedules, and often were not given the necessary training to do their job. Additionally, many families that used home care services told stories of unprofessional caregivers being sent to their homes, last minute cancellations, and overall a lack of consistency with caring professionals for their loved ones.
        </p>
        <p>
          As the stories began to pile up the CCCD resolved to develop a worker-owned home care cooperative in Yolo County where caregivers can enjoy higher wages, professional development opportunities, have their voices and concerns heard, and most importantly experience life changing empowerment in their workplace.
        </p>
        <p>
          A worker-cooperative is run and operated by its workers. This allows worker-owners to be deeply involved in the cooperative's success. National data by the International Cooperative Alliance has shown how a cooperative can have a transformative impact on an industry as well as the workers that own it.
        </p>
        <p>
          Co-op Home Care was launched in 2020. As a new kind of home care agency that is owned by the people who offer the care, caregivers at Co-op Home Care:
        </p>

        <ul>
          <li> <span>Own and operate the business</span> - This allows caregivers to be incontrol of the policies, procedures, and direction of the agency and develop policies that are fair and consider the caregivers work-life balance. </li>
          <li>
          <span>Share the profits of the business</span> - As co-owners of the agency, caregivers have a unique opportunity to split the profits of the agency making Co-op Home Care highly competitive in the industry.
          </li>
          <li>
          <span>Care for each other</span> - Caregivers at Co-op Home Care not only care for their clients, but they care about each other. The cooperative offers extensive caregiver training, cooperative business training, and life-changing advancement opportunities for its workers. A comprehensive mentorship program provides a solid foundation for new caregivers to launch a new and exciting career.
          </li>
        </ul>

        <p>
          Caregivers at Co-op Home Care are happy with their workplace. They are dedicated not only to their clients, but to maintaining an atmosphere of support, solidarity, and teamwork that inspires dedication and the constant improvement of the day-to-day operations that affect caregivers’ lives, and in turn translates to long lasting committed care for the families that they serve.
        </p>


      </div>
  

      </Layout>
  )
}

export default OurStoryPage
